import { SEARCH_RESULT_TYPES } from '@/constants'

export const getElementTypes = (elementType = '') => {
  const { OPTIONS, CHOICES, COMPONENTS, COMPONENT_VARIANTS } = SEARCH_RESULT_TYPES
  let type = { type: '', subtype: '' }

  if ([OPTIONS, CHOICES].includes(elementType)) {
    type = { type: OPTIONS, subtype: CHOICES }
  }
  else if ([COMPONENTS, COMPONENT_VARIANTS].includes(elementType)) {
    type = { type: COMPONENTS, subtype: COMPONENT_VARIANTS }
  }

  return type
}

export const filterElementByString = ({ element = {}, searchString = '' } = {}) => {
  const searchStringLower = searchString.toLowerCase()
  const id2Lower = element.id2?.toLowerCase() || ''
  const nameLower = element.name?.toLowerCase() || ''

  return id2Lower.includes(searchStringLower) || nameLower.includes(searchStringLower)
}

export const filterElementsListByString = ({ elementsList = [], elementType = '', searchString = '' }) => {
  const elementSubType = getElementTypes(elementType).subtype
  return Object.freeze(elementsList
    .map(element => {
      element[elementSubType] = element[elementSubType]?.filter(
        subElement => filterElementByString({ element: subElement, searchString })
      ) || []
      return element
    })
    .filter(element => filterElementByString({ element, searchString })
      || element[elementSubType]?.length)) || []
}

export const getElementIdsReference = elementType => {
  let elementId = 'optionId'
  let elementId2 = 'optionId2'
  let elementName = 'optionName'

  if (elementType === SEARCH_RESULT_TYPES.COMPONENT_VARIANTS) {
    elementId = 'componentId'
    elementId2 = 'componentId2'
    elementName = 'componentName'
  }

  return { elementId, elementId2, elementName }
}

export const filterBySubstring = (
  arr,
  substring
) => arr.filter(item => item.toLowerCase().includes(substring.toLowerCase()))
